import http from '@/common/utils/http'

/** 获取维修列表 */
export function queryFaultList(data) {
    return http({
        url: `fault/searchFaultList`,
        method: 'post',
        data
    })
}


/** 获取故障详情 */
export function getFaultDetail(data) {
    return http({
        url: `fault/getFaultDetail/${data}`,
        method: 'get'
    })
}

/**查询故障类型*/
export function getFaultDefinitionSearch(data1, data2) {
    return http({
        url: `/fault/definition/definitionSearch/${data1}/${data2}`,
        method: 'get'
    })
}

/** 维修Pdf */
export function generateRepairPdf(params) {
    return http({
        url: `RuiJin/generateRepairPdf`,
        method: 'post',
        params
    })
}

/** 救援Pdf */
export function generateRescuePdf(params) {
    return http({
        url: `RuiJin/generateRescuePdf`,
        method: 'post',
        params
    })
}

/** 困人处理进度  */
export function getElevatorFaultRealTimeByEvent(data) {
    return http({
        url: `RuiJin/getElevatorFaultRealTime/${data}`,
        method: 'get',
        data
    })
}

export function exportExcel(data) {
    return http({
        url: `fault/excel`,
        method: 'post',
        responseType: 'blob',
        data
    })
}