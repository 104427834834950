import http from '@/common/utils/http'

/** 获取不文明行为列表 */
export function searchFaultList(data) {
  return http({
    url: `fault/searchFaultList`,
    method: 'post',
    data
  })
}

export function exportExcel(data) {
    return http({
        url: `fault/excel`,
        method: 'post',
        responseType: 'blob',
        data
    })
}